<script>
	import { computed } from "vue";
	import moment from "moment";
	export default {
		name: "MapInfoWindow",
		props: ["bus"],
		setup(props) {
			const formattedTime = computed(() => {
				return moment
					.utc(props.bus.gps_position.time)
					.local()
					.format("lll");
			});
			const isNotShowVar = ["Command"];
			const showingSensor = computed(() => {
				return props.bus.gps_position.sensors.filter(
					(sensor) => !isNotShowVar.includes(sensor.name)
				);
			});
			return { formattedTime, showingSensor };
		},
	};
</script>

<template>
	<div class="col-sm-12">
		<div class="row overflow-auto">
			<div class="col">
				<dl>
					<dt>Vehicle No.</dt>
					<dd>{{ bus.vehicle_no }}</dd>
					<dt>Vehicle Code</dt>
					<dd>{{ bus.vehicle_code }}</dd>
					<dt>Speed</dt>
					<dd>{{ bus.gps_position.speed }}</dd>
					<dt>Online</dt>
					<dd>{{ bus.gps_position.online }}</dd>
					<dt>Stop Duration</dt>
					<dd>{{ bus.gps_position.stop_duration }}</dd>
					<dt>Address</dt>
					<dd>{{ bus.gps_position.address }}</dd>
					<dt>Last Update</dt>
					<dd>{{ formattedTime }}</dd>
					<dt>Sensor</dt>
					<dd>
						<ul>
							<template v-for="(sensor, key) in showingSensor" :key="key">
								<li>{{ sensor.name }}: {{ sensor.value }}</li>
							</template>
						</ul>
					</dd>
				</dl>
			</div>
		</div>
	</div>
</template>
